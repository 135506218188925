<template>
    <div>
        <div class="vx-row mt-3">
            <header class="vx-col w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-10">
                <h4>{{ $t("apps.registered-users.users") }}</h4>
                <p>{{ $t("apps.registered-users.userslist") }}</p>
            </header>
            <div class="vx-col w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-10 text-right space-x-4">
                <vs-button class="xs:w-full sm:w-auto rounded-lg" :to="{ name: 'export-registered-users' }" >
                    {{ $t("apps.registered-users.exportcsv") }}
                </vs-button>
            </div>
        </div>
        <div>
            <Datatable 
                :config="datatableConfig"
                :elements="registeredusers"
                :stripe="true"
                :draggable="false"
                :sst="true"
                :pagination="pagination"
                @filter="filter"
            />
        </div>
    </div>
</template>

<script>
import { DateTime } from 'luxon';
import { mapGetters } from 'vuex';
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import PaginationService from '@/modules/Shared/Services/PaginationService'
import RegisteredUsersService from '@/modules/Apps/Services/RegisteredUsersService'

export default {
    name: 'registeredusers',
    components: {
        Datatable
    },
    data () {
        return {
            registeredusers: [],
            datatableConfig: [
                { 'type': 'text', 'field': 'username', head: this.$t("apps.registered-users.username"), 'searchable': true, 'sorteable': true },
                { 'type': 'text', 'field': 'email', head: this.$t("apps.registered-users.email"), 'searchable': true, 'sorteable': true },
                { 'type': 'text', 'field': 'name', head: this.$t("apps.registered-users.firstname"), 'searchable': true, 'sorteable': true },
                { 'type': 'text', 'field': 'surname', head: this.$t("apps.registered-users.surname"), 'searchable': true, 'sorteable': true },
                { 'type': 'text', 'field': 'register_date', head: this.$t("apps.registered-users.registrationdate"), 'searchable': true, 'sorteable': true },
                { 'type': 'text', 'field': 'birthday', head: this.$t("apps.registered-users.birthday"), 'searchable': true, 'sorteable': true },
                { 'type': 'text', 'field': 'language', head: this.$t("apps.registered-users.language"), 'searchable': true, 'sorteable': true }
            ],
            pagination: {}
        }
    },
    async created() {
        this.getRegisteredUsers({})
    },
    computed: {
        ...mapGetters( ['applicationUuid'] )
    },
    methods: {
        async getRegisteredUsers(parameters) {
            const usersData = await RegisteredUsersService.getRegisteredUsers(this.applicationUuid, parameters)

            this.pagination = {
                per_page: usersData.per_page,
                total: usersData.total
            }

            this.registeredusers = usersData.data.map(
                user => ({
                    ...user, 
                    'birthday': user.birthday ? DateTime.fromISO(user.birthday).toFormat('yyyy-LL-dd') : null,
                    'register_date': user.register_date ? DateTime.fromISO(user.register_date).toFormat('dd-LL-yyyy') : null
                })
            )
        },
        filter(params) {
            this.getRegisteredUsers(PaginationService.parseParameters(params))
        }
    }
}
</script>
